import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: "home",
    url: "apps/dashboard",
  },
  {
    id: "department",
    title: "Quản lý khoa, lớp",
    type: "item",
    icon: "package",
    url: "apps/department",
  },
  {
    id: "student",
    title: "Quản lý sinh viên",
    type: "item",
    icon: "users",
    url: "apps/student",
  },
  {
    id: "graduation-decision",
    title: "Quyết định tốt nghiệp",
    type: "item",
    icon: "book",
    url: "apps/graduation-decision",
  },
];
