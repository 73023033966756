import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: "home",
    url: "apps/dashboard",
  },
  {
    id: "department",
    title: "Quản lý khoa, lớp",
    type: "item",
    icon: "package",
    url: "apps/department",
  },
  {
    id: "student",
    title: "Quản lý sinh viên",
    type: "item",
    icon: "users",
    url: "apps/student",
  },
  {
    id: "graduation-decision",
    title: "Quyết định tốt nghiệp",
    type: "item",
    icon: "book",
    url: "apps/graduation-decision",
  },
  {
    id: "document-type",
    title: "Quản lý loại tài liệu",
    type: "item",
    icon: "archive",
    url: "apps/document-type",
  },
  {
    id: "document-manage",
    title: "Quản lý tài liệu",
    type: "item",
    icon: "book-open",
    url: "apps/document-manage",
  },
  {
    id: "api-key",
    title: "Quản lý người dùng",
    type: "item",
    icon: "user-check",
    url: "apps/api-key",
  },
];
