import { NgModule } from "@angular/core";

import { FeatherIconDirective } from "@core/directives/core-feather-icons/core-feather-icons";
import { RippleEffectDirective } from "@core/directives/core-ripple-effect/core-ripple-effect.directive";
import { InputTrimDirective } from "./core-trim-input/input-trim-directive";
import { AppRemoveSpace } from "./core-trim-input/remove-space.directive";
import { FormControlValidationDirective } from "./form-control-validation/form-control-validation.directive";
@NgModule({
  declarations: [
    RippleEffectDirective,
    FeatherIconDirective,
    InputTrimDirective,
    AppRemoveSpace,
    FormControlValidationDirective,
  ],
  exports: [
    RippleEffectDirective,
    FeatherIconDirective,
    InputTrimDirective,
    AppRemoveSpace,
    FormControlValidationDirective,
  ],
})
export class CoreDirectivesModule {}
