import { Pipe, PipeTransform } from "@angular/core";
import { DragDropItem } from "@core/components/custom-drag-drop/drag-drop-model";
import { Signature } from "app/models/signature/Signature";


@Pipe({ name: "dragDropPipe" })
export class DragDropPipe implements PipeTransform {
  transform(
    value: Signature[] | DragDropItem[],
    type: "signaturesToDragDrops" | "dragDropsToSignature"
  ): DragDropItem[] | Signature[] {    
    if (type == "signaturesToDragDrops")
      return value.map(
        (item: Signature) =>
          <DragDropItem>{
            positionX: item.x,
            positionY: item.y,
            width: item.width,
            height: item.height,
            fontSize: item.fontSize,
            image: item.srcImg,
            page: item.pageNumber,
            id: item.id,
            text: item.text,
            fontFamily: item.fontFamily,
            email: item.email,
            userParticipantId: item.userParticipantId,
            canEdited: item.canEdited,
            canRemoved: item.canRemoved,
          }
      );
    else
      return value.map(
        (item: DragDropItem) =>
          <Signature>{
            id: item.id,
            pageNumber: item.page,
            x: item.positionX,
            y: item.positionY,
            width: item.width,
            height: item.height,
            fontSize: item.fontSize,
            srcImg: item.image,
            userParticipantId: item.userParticipantId,
            certificateInfo: null,
            inFlow: null,
            email: item.email,
            fullName: null,
            text: item.text,
            fontFamily: item.fontFamily,
            canEdited: item.canEdited,
            canRemoved: item.canRemoved,
          }
      );
  }
}
