import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { registerLicense } from "@syncfusion/ej2-base";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import { hmrBootstrap } from "./hmr";

registerLicense(
  "Mgo+DSMBaFt/QHRqVVhlWFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jQXxQdkdnWXtZdXJdRA==;Mgo+DSMBPh8sVXJ0S0J+XE9BclRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TfkdgWX1acHBSQ2hUUw==;ORg4AjUWIQA/Gnt2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdk1hWn9cdHRRQWZcVUY=;MjY1NzY3N0AzMjMwMmUzNDJlMzBMakkwR2tkOGZxREdYUjF3TGxyS3Z1K2luZVJ3Tmg2S0dwWHZxWDVSOHhBPQ==;MjY1NzY3OEAzMjMwMmUzNDJlMzBtNFdIMXVNejBQRSs3bXJWTUd5UzMwWVZtMFZhRzVqUXVPQy80RVdBa1o0PQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFpGVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVrW3xecnBVQmdbUEJ0;MjY1NzY4MEAzMjMwMmUzNDJlMzBDbmVPNWVXZTdEZXoxcDBFdUZ1S3A1RnJjNDZsbTFReUdJM0lYZS9weHNnPQ==;MjY1NzY4MUAzMjMwMmUzNDJlMzBiQmR3c21pdlpyTm5FOVRkSEN3NkVYSklhcTUxZjRDbkx1eTg3Z05ua0NnPQ==;Mgo+DSMBMAY9C3t2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdk1hWn9cdHRRQWhcUUY=;MjY1NzY4M0AzMjMwMmUzNDJlMzBGdEZCM3V2RE9vMFluVk5zOHRidDhyRFkyK0JQSW5KS3BDVGRhVzZXcUlJPQ==;MjY1NzY4NEAzMjMwMmUzNDJlMzBqUWtVN2dIWmRya01CTUNJTXNEcTJvS3F3UzNzREtRRUpYMHhydUdEc0JRPQ==;MjY1NzY4NUAzMjMwMmUzNDJlMzBDbmVPNWVXZTdEZXoxcDBFdUZ1S3A1RnJjNDZsbTFReUdJM0lYZS9weHNnPQ=="
);
if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module["hot"]) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error("HMR is not enabled for webpack-dev-server!");
    console.log("Are you using the --hmr flag for ng serve?");
  }
} else {
  bootstrap().catch((err) => console.log(err));
}
