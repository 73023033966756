import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import {
  FormControlName,
  FormGroupDirective,
  ValidationErrors,
} from "@angular/forms";
import { Subscription, merge } from "rxjs";
import { distinctUntilChanged, startWith } from "rxjs/operators";

@Directive({
  selector: "[appFormControlValidation]",
})
export class FormControlValidationDirective implements OnInit, OnDestroy {
  private _subscription: Subscription;

  constructor(
    private _elementRef: ElementRef<HTMLInputElement>,
    private _formControl: FormControlName,
    private _renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const invalidFeedBackDiv = this._renderer.createElement(
      "div"
    ) as HTMLDivElement;
    this._renderer.addClass(invalidFeedBackDiv, "invalid-feedback");
    this._elementRef.nativeElement.insertAdjacentElement(
      "afterend",
      invalidFeedBackDiv
    );

    const formGroupDirective = this._formControl
      .formDirective as FormGroupDirective;

    this._subscription = merge(
      this._formControl.valueChanges,
      formGroupDirective.ngSubmit
    )
      .pipe(startWith({}), distinctUntilChanged())
      .subscribe(() => {
        if (
          this._formControl.errors &&
          (this._formControl.dirty || formGroupDirective.submitted)
        ) {
          this._renderer.addClass(this._elementRef.nativeElement, "is-invalid");
          invalidFeedBackDiv.textContent = this.getErrorMessage(
            this._formControl.errors
          );
        } else {
          this._renderer.removeClass(
            this._elementRef.nativeElement,
            "is-invalid"
          );
          invalidFeedBackDiv.textContent = "";
        }
      });
  }

  getErrorMessage(errors: ValidationErrors) {
    const errorKeys = Object.keys(this._formControl.errors || {});
    switch (errorKeys[0]) {
      case "email":
        return "Email chưa đúng định dạng";
      case "required":
        return "Không được bỏ trống";
      case "minlength":
        return `Nhập tối thiểu ${errors.minlength.requiredLength} ký tự`;
      case "maxlength":
        return `Nhập tối đa ${errors.maxlength.requiredLength} ký tự`;
      case "min":
        return `Giá trị tối thiểu là ${errors.min.min}`;
      case "max":
        return `Giá trị tối đa là ${errors.max.max}`;
      case "duplicateEmail":
        return "Email đã tồn tại";
      case "pattern":
        return "Email chưa đúng định dạng. Hãy nhập đầy đủ phần tên miền (ví dụ: .com, .net)";
      case "customError":
        return errors.customError;
      default:
        return "Không hợp lệ";
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
