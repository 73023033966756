<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            width="36"
          />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <a
        type="button"
        class="nav-link nav-link-style btn"
        (click)="toggleDarkSkin()"
      >
        <span
          [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'"
          class="ficon font-medium-5 feather"
        ></span>
      </a>
    </li>
    <!--/ Toggle skin -->
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- ? Language selection | Uncomment if needed-->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{
            currentUser.fullName
          }}</span
          ><span class="user-status">{{
            this.currentUser.role === "SUPER_ADMIN"
              ? "Quản trị cấp cao"
              : this.currentUser.role === "ADMIN"
              ? "Quản trị viên"
              : this.currentUser.role === "USER"
              ? "Người dùng"
              : null
          }}</span>
          <span class="text-primary h6 m-0">
            {{ tennantName }}
          </span>
        </div>
        <span class="avatar" *ngIf="this.currentUser.role === 'SUPER_ADMIN'"
          ><img
            class="round"
            src="https://{{ this.currentUser.avatar }}"
            onerror="this.src='assets/images/portrait/small/super-admin.png'"
            alt="avatar"
            height="40"
            width="40" /><span class="avatar-status-online"></span
        ></span>
        <span class="avatar" *ngIf="this.currentUser.role === 'ADMIN'"
          ><img
            class="round"
            src="https://{{ this.currentUser.avatar }}"
            onerror="this.src='assets/images/portrait/small/organizations.jpg'"
            alt="avatar"
            height="40"
            width="40" /><span class="avatar-status-online"></span
        ></span>
        <span class="avatar" *ngIf="this.currentUser.role === 'USER'"
          ><img
            class="round"
            src="https://{{ this.currentUser.avatar }}"
            onerror="this.src='assets/images/portrait/small/users.png'"
            alt="avatar"
            height="40"
            width="40" /><span class="avatar-status-online"></span
        ></span>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <a ngbDropdownItem [routerLink]="['/']"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        >
        <ng-container *ngFor="let item of allTennant">
          <a
            ngbDropdownItem
            href="javascript:void(0)"
            (click)="changeTennant(item)"
            ><span [data-feather]="'box'" [class]="'mr-50'"> </span
            >{{ item.tenant_name }}</a
          >
        </ng-container>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="logout()"
          ><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a
        >
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
